.comp-header {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .comp-header--description {
            @include type--lead;
            display: block;
            margin-top: 16px;
        }

        .comp-header--text {
            display: block;
            margin-top: 8px;
        }

        .comp-header--tags {
            margin-top: 24px;

            @include mq('<=xl') {
                display: none;
            }
        }

        .comp-header--anchor {
            margin-top: 24px;
        }

        .comp-header--content-wrapper {
            @include overlay;
            z-index: 1;
            display: flex;
            align-items: center;
            height: initial;
            flex-flow: row nowrap;

            @include mq('md') {
                margin-top: 40px;
            }

            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .comp-header--content-inner-wrapper {
            width: 100%;

            @include mq('>md') {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            @include mq('print') {
                position: static;
                transform: translateY(0);
            }
        }
    }

    // Facets
    & {
        &.comp-header__hero {
            .comp-header--title {
                background-color: $c--monochrome-white;

                @include mq('>md') {
                    padding: 16px 24px 16px 0;
                }
            }
        }
    }
    & {
        &.comp-header__base {
            .comp-header--content-inner-wrapper {
                @include mq('>md') {
                    position: relative;
                    margin-top: -60px;
                }
            }
        }
    }

    // States
}
